import React from 'react';

import Footer from '@components/footer';
import HeaderNavigation from '@components/header';
import { FirstConatiner, MainContainer } from '@components/shared/common';
import { LayoutProps } from '@models/common/layout';

const Layout: React.FunctionComponent<LayoutProps> = ({
  headerProps,
  footerProps,
  children,
}) => (
  <FirstConatiner>
    <MainContainer>
      <HeaderNavigation {...headerProps} />
      {children}
    </MainContainer>
    <Footer {...footerProps} />
  </FirstConatiner>
);

export default Layout;

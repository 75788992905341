import React from 'react';
import { NextPage } from 'next';

import { SvgIcon } from '@components/icon';
import Layout from '@components/layout';
import Meta from '@components/meta-tag';
import { Header, MediumText } from '@components/shared/common';
import useStaticPageSegment from '@services/analytics/use-analytics';
import ThankYou from '@components/thank-you';

const ErrorPage: NextPage = () => {
  const showThankYouPage = true;
  const { trackStaticPageEvents } = useStaticPageSegment();

  React.useEffect(() => {
    trackStaticPageEvents('404 Error');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preOpeningPhase: string | undefined =
    process.env.NEXT_PUBLIC_PRE_OPENING_PHASE;

  return (
    <>
      {showThankYouPage ? (
        <div>
          <ThankYou />
        </div>
      ) : (
        <>
          <Meta titleSuffix="Oops" />
          <Layout
            headerProps={{
              showBookButton: true,
              showMenuOption: true,
              ispreOpening: !!preOpeningPhase,
            }}
            footerProps={{ large: true, ispreOpening: !!preOpeningPhase }}
          >
            <div css={{ textAlign: 'center' }}>
              <Header>Whoops</Header>
              <MediumText
                fWeight="500"
                pb="25px"
                align="center"
                style={{ display: 'block' }}
              >
                This page doesn&apos;t exist. Hope you find what you&apos;re
                looking for!
              </MediumText>
              <SvgIcon width="65px" src="search-gradient.svg" />
            </div>
          </Layout>
        </>
      )}
    </>
  );
};

export default ErrorPage;
